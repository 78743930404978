$j(document).ready(function() {
	Frontend.initLoad();
	Frontend.initMmenu();
	Frontend.initNewsList();
	Frontend.initAskList();
	Frontend.initRatings();
});

$j(window).resize(function() {
	Frontend.initMmenu();
});

// back button fix FF
$j(window).unload(function() {});

// back button fix Safari
window.onpageshow = function(event) {
    $j('body').addClass('loaded');
};